<template>
  <div>
    <h1>Empresa e franquia</h1>
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="details">
      <b-row v-if="editEnabled" class="justify-content-end">
        <b-col cols="auto">
          <b-button
            variant="outline-primary"
            @click.prevent="goToEditCompanyDetails"
          >
            <feather-icon icon="EditIcon" class="mr-50" />
            <span>Editar</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Nome da franquia </small>
            <h5>
              {{ details.franchise_fantasy_name || "Nome não informado" }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Franquia do líder </small>
            <h5>
              {{ details.leader_franchise_name || "Nome não informado" }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Razão social </small>
            <h5>
              {{ details.company_name || "Nome não informado" }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Endereço </small>
            <h5>
              {{ details.company_address || "Endereço não informado" }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> CNPJ </small>
            <h5 v-if="details.franchise_cnpj">
              {{ details.franchise_cnpj | cpfCnpj }}
            </h5>
            <h5 v-else>CNPJ não informado</h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted">
              Pix
              {{
                details.franchise_pix_type_key
                  ? `(${details.franchise_pix_type_key})`
                  : ""
              }}
            </small>
            <h5>
              {{ details.franchise_pix || "Pix não informado" }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" cols="12" class="mt-1">
          <div>
            <small class="text-muted"> Comprovante de endereço </small>
            <h5>
              <a
                v-if="details.company_address_file_url"
                :href="details.company_address_file_url"
                target="_blank"
              >
                {{ details.company_address_file | onlyFileName }}
              </a>
              <span v-else>Comprovante não informado</span>
            </h5>
          </div>
        </b-col>
        <b-col md="6" cols="12" class="mt-1">
          <div>
            <small class="text-muted"> Cartão CNPJ </small>
            <h5>
              <a
                v-if="details.social_contract_file_url"
                :href="details.social_contract_file_url"
                target="_blank"
              >
                {{ details.social_contract_file | onlyFileName }}
              </a>
              <span v-else>Comprovante não informado</span>
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" cols="12" class="mt-1">
          <div>
            <small class="text-muted"> Identificação do responsável </small>
            <h5>
              <a
                v-if="details.partner_identification_file_url"
                :href="details.partner_identification_file_url"
                target="_blank"
              >
                {{ details.partner_identification_file | onlyFileName }}
              </a>
              <span v-else>Comprovante não informado</span>
            </h5>
          </div>
        </b-col>
        <b-col md="6" cols="12" class="mt-1">
          <div>
            <small class="text-muted"> Foto </small>
            <h5>
              <a
                v-if="details.picture_file_url"
                :href="details.picture_file_url"
                target="_blank"
              >
                {{ details.picture_file | onlyFileName }}
              </a>
              <span v-else>Foto não informada</span>
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Endereço pessoal </small>
            <h5 v-if="details.personal_address">
              {{ details.personal_address | cpfCnpj }}
            </h5>
            <h5 v-else>Endereço pessoal não informado</h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Celular </small>
            <h5 v-if="details.cell_phone">
              {{ details.cell_phone | formatPhone }}
              <b-button
                class="btn-icon"
                variant="flat"
                style="padding: 0; vertical-align: bottom"
                @click.prevent="goToWhatsapp(details.cell_phone)"
              >
                <feather-icon
                  icon="MessageCircleIcon"
                  size="16"
                  class="text-body"
                  stroke="green"
                />
              </b-button>
            </h5>
            <h5 v-else>Celular não informado</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div class="d-flex flex-column">
            <small class="text-muted"> Linkedin </small>

            <a
              :href="`http://linkedin.com/company/${details.linkedin}`"
              target="_blank"
              rel="noopener noreferrer"
              v-if="details.linkedin"
            >
              {{ details.linkedin }}
            </a>

            <h5 v-else>Linkedin não informado</h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div class="d-flex flex-column">
            <small class="text-muted"> Instagram </small>
            <a
              :href="`http://instagram.com/${details.instagram}`"
              target="_blank"
              rel="noopener noreferrer"
              v-if="details.instagram"
            >
              {{ details.instagram }}
            </a>

            <h5 v-else>Instagram não informado</h5>
          </div>
        </b-col>
      </b-row>
      <template v-if="details.transferred">
        <b-row>
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> Líder antigo </small>
              <h5>
                {{ details.transferred.old_leader_name }}
              </h5>
            </div>
          </b-col>
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> Franquia antiga </small>
              <h5>
                {{ details.transferred.old_leader_franchise }}
              </h5>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> Novo líder direto </small>
              <h5>
                {{ details.transferred.new_leader_name }}
              </h5>
            </div>
          </b-col>
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> Nova franquia </small>
              <h5>
                {{ details.transferred.new_leader_franchise }}
              </h5>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> Data de transferência </small>
              <h5>
                {{ details.transferred.date | dateTime }}
              </h5>
            </div>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BSpinner,
  BButton,
  BPopover,
  BCardText,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import * as types from "@/modules/consultant/store/types";
import * as accountTypes from "@/modules/account/store/types";
import { useToast } from "vue-toastification/composition";
import * as authTypes from "@/constants/auth";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BButton,
    BPopover,
    BCardText,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      alias: undefined,
    };
  },
  computed: {
    ...mapGetters({
      details: types.DETAILS_COMPANY_DATA,
      user: accountTypes.USER,
    }),
    editEnabled: function () {
      return (
        this.user?.user_role_id == authTypes.APP_BACKOFFICE_ROLE_ID ||
        this.user?.user_role_id == authTypes.APP_FINANCIAL_ROLE_ID ||
        this.user?.user_role_id == authTypes.APP_ADMIN_ROLE_ID ||
        (this.user?.appify_consultant_id &&
          this.user?.appify_consultant_id === parseInt(this.$route.params.id))
      );
    },
  },
  mounted() {
    this.loading = true;
    this.get();
  },
  methods: {
    ...mapActions({
      getDetailsCompanyData: types.GET_DETAILS_COMPANY_DATA
    }),
    goToWhatsapp(phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + phone, "_blank").focus();
      });
    },
    goToEditCompanyDetails() {
      this.$router.push({
        name: "consultant-company-info-save",
        params: { id: this.$route.params.id },
      });
    },
    get() {
      this.getDetailsCompanyData(this.$route.params.id)
        .then((response) => {
          if (this.details.alias) {
            this.alias = this.details.alias;
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter as informações da empresa. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.$router.push({ name: "consultants-list" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
